<template>
  <div class="FormWrap">
    <!-- <mia-navback :isShowBack="false" :isShowSave="true" @submit="EditCompany" /> -->
    <div class="content" v-loading="loading">
      <mia-Card title="商品设置">
        <div style="margin-left: 4px" slot="content">
          <el-form :model="form" label-width="150px">
            <el-form-item
              v-for="(item, index) in BasicFields"
              :key="index"
              :label="item.name"
              :prop="item.field"
              :name="item.field"
            >
              <el-switch
                v-model="form[item.field]"
                :active-value="item.activeValue"
                :inactive-value="item.inactiveValue"
                @change="change(item.field)"
              ></el-switch>
              <el-tooltip
                class="tip"
                effect="dark"
                :content="item.tooltip"
                placement="right"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </el-form-item>
          </el-form>
        </div>
      </mia-Card>
      <!-- <mia-Card title="财务配置">
        <div style="margin-left: 4px" slot="content">
          <el-form :model="form" label-width="150px">
            <el-form-item
              v-for="(item, index) in financeFields"
              :key="index"
              :label="item.name"
              :prop="item.field"
              :name="item.field"
            >
              <el-row>
                <el-col :span="6">
                  <el-select
                    v-model="form[item.field]"
                    style="width: 100%"
                    filterable
                    clearable
                    :placeholder="'请选择' + item.name"
                  >
                    <el-option
                      v-for="(option, optionIndex) in item.options"
                      :key="optionIndex"
                      :label="option.label"
                      :value="option.value"
                    />
                  </el-select>
                </el-col>
                <el-col :span="6" :push="1">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.tooltip"
                    placement="right"
                  >
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
        </div>
      </mia-Card> -->
      <mia-Card title="库存配置">
        <div style="margin-left: 4px" slot="content">
          <el-form :model="form" label-width="150px">
            <el-form-item
              v-for="(item, index) in inventoryFields"
              :key="index"
              :label="item.name"
              :prop="item.field"
              :name="item.field"
            >
              <el-switch
                v-model="form[item.field]"
                :active-value="item.activeValue"
                :inactive-value="item.inactiveValue"
                @change="change(item.field)"
              ></el-switch>
              <el-tooltip
                class="tip"
                effect="dark"
                :content="item.tooltip"
                placement="right"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </el-form-item>
          </el-form>
        </div>
      </mia-Card>
      <mia-Card title="销售设置">
        <div style="margin-left: 4px" slot="content">
          <el-form :model="form" label-width="150px">
            <el-form-item
              v-for="(item, index) in saleFields"
              :key="index"
              :label="item.name"
              :prop="item.field"
              :name="item.field"
            >
              <el-switch
                v-model="form[item.field]"
                :active-value="item.activeValue"
                :inactive-value="item.inactiveValue"
                @change="change(item.field)"
              ></el-switch>
              <el-tooltip
                class="tip"
                effect="dark"
                :content="item.tooltip"
                placement="right"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </el-form-item>
          </el-form>
        </div>
      </mia-Card>
    </div>
  </div>
</template>
<script>
import { GetParameter, EditParameter } from "@/api/system";
export default {
  data() {
    return {
      loading: false,
      userId: null,
      form: {
        isMulUnit: "0",
        isBatch: "0",
        isMinus: "0",
        isMemberPrice: "0",
      },
      BasicFields: [
        {
          name: "商品多计量单位",
          field: "isMulUnit",
          activeValue: "1",
          inactiveValue: "0",
          tooltip:
            "开启后，商品可以多个单位，例如基本单位“瓶”，辅助单位“箱”，1箱=20瓶",
        },
        {
          name: "商品会员价",
          field: "isMemberPrice",
          activeValue: "1",
          inactiveValue: "0",
          tooltip: "开启后，可以给商品设置会员价，并给客户预设该会员价",
        },
        // {
        //   name: "商品批次管理",
        //   field: "isBatch",
        //   type: "select",
        //   options: [
        //     { label: "关闭", value: '0' },
        //     { label: "开启", value: '1' },
        //   ],
        //   tooltip:
        //     "启用后，进货、销售时，需填写对应的批次号，并且可查询商品各批次的保质期状态。",
        // },
        // {
        //   name: "商品序列码管理",
        //   field: "isSerial",
        //   type: "select",
        //   options: [
        //     { label: "关闭", value: '0' },
        //     { label: "开启", value: '1' },
        //   ],
        //   tooltip:
        //     "商品每个包装上都有唯一的序列号或二维码，并且需要跟踪每个商品的进货、销售状态，可开启该选项。开启后，进货、销售时，需填写对应的序列号或二维码。",
        // },
      ],
      //   financeFields: [
      //     {
      //       name: "存货计价方法",
      //       field: "costType",
      //       type: "select",
      //       options: [
      //         { label: "移动平均法", value: 1 },
      //         { label: "先进先出法", value: 2 },
      //       ],
      //       tooltip: "修改后，会导致成本、利润数据变动，请谨慎修改。",
      //     },
      //   ],
      inventoryFields: [
        {
          name: "允许负库存",
          field: "isMinus",
          activeValue: "1",
          inactiveValue: "0",
          tooltip:
            "开启后，允许负库存出库，可能会造成库存统计错误，建议不要开启",
        },
        {
          name: "库存预警",
          field: "isEarly",
          activeValue: "1",
          inactiveValue: "0",
          tooltip: "开启后，可以设置商品的库存上、下限，超过限制会提示",
        },
      ],
      saleFields: [
        {
          name: "商品改价",
          field: "isRepricing",
          activeValue: "1",
          inactiveValue: "0",
          tooltip: "开启后，销售过程中，允许对商品价格进行修改",
        },
        {
          name: "整单改价",
          field: "isAllRepricing",
          activeValue: "1",
          inactiveValue: "0",
          tooltip: "开启后，销售过程中，允许对整单价格进行修改",
        },
      ],
    };
  },
  created() {
    this.userId = sessionStorage.getItem("userId");
    this.GetConfig();
  },
  methods: {
    GetConfig() {
      this.loading = true;
      GetParameter().then((res) => {
        const { code, data, msg } = res;
        if (code === 1) {
          this.form = { ...data };
        } else {
          this.$message.error(msg);
        }
        this.loading = false;
      });
    },
    change(field) {
      const result = { id: this.form.id };
      result[field] = this.form[field];
      EditParameter(result).then((res) => {
        const { code, msg } = res;
        if (code === 1) {
          this.$message.success(msg);
        } else {
          this.$message.error(msg);
        }
        this.GetConfig();
      });
    },
    EditCompany() {
      this.$confirm(
        `是否保存当前参数？保存成功后将关闭所有已打开的标签页`,
        "提示",
        {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        }
      ).then(() => {
        EditParameter(this.form).then((res) => {
          const { code, msg } = res;
          if (code === 1) {
            this.$message.success(msg);
            this.GetConfig();
            this.$emit("closeAll");
          } else {
            this.$message.error(msg);
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tip {
  margin-left: 20px;
  font-size: 16px;
}
</style>
